/* Container and Layout */
.page-container {
  width: 75%;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.main-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.search-bar {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  font-size: 16px;
}

/* Table Styling */
.table-responsive {
  overflow-x: auto;
  width: 100%;
  margin-bottom: 20px;
}

.inspections-table {
  width: 100%;
  border-collapse: collapse;
}

.inspections-table th,
.inspections-table td {
  padding: 12px;
  border: 1px solid #ccc;
  text-align: left;
  white-space: nowrap;
}

.inspections-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  cursor: pointer;
}

.inspections-table th:hover {
  background-color: #e0e0e0;
}

.inspections-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.inspections-table th:last-child,
.inspections-table td:last-child {
  text-align: center;
}

/* Button Styles */
.button {
  background-color: #fe2400;
  color: white;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto;
  display: inline-block;
}

.button:hover {
  background-color: #d43f1c;
}

.button:focus {
  outline: none;
}

.button.close-button {
  background-color: #777;
  margin-top: 20px;
}

.button.close-button:hover {
  background-color: #555;
}

/* Modal Styling */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  outline: none;
  border: 2px solid #ccc;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-heading {
  text-align: center;
  font-size: 2rem;
  margin: 0 0 20px 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #dee2e6;
}

/* Inspection Details Styling */
.inspection-header {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #dee2e6;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.inspection-header p {
  margin: 5px 0;
}

/* Section and Question Styling */
.section-container {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.section-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.section-title::after {
  content: '▼';
  position: absolute;
  right: 0;
  transition: transform 0.2s;
}

.section-title.expanded::after {
  transform: rotate(180deg);
}

.section-title:hover {
  color: #fe2400;
}

.question-container {
  margin-bottom: 15px;
  padding-left: 20px;
}

.question-text,
.response-text {
  font-size: 1rem;
  margin-bottom: 5px;
}

.question-text strong,
.response-text strong {
  color: #555;
}

.response-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Scoring Styles */
.scores-summary {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
  border: 1px solid #dee2e6;
}

.scores-summary h4 {
  color: #333;
  margin: 0 0 15px 0;
  padding-bottom: 8px;
  border-bottom: 2px solid #fe2400;
}

.section-score {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 8px;
}

.section-score p {
  margin: 0;
}

.total-score {
  background-color: #fe2400;
  color: white;
  padding: 12px;
  border-radius: 6px;
  margin-top: 15px;
  text-align: center;
}

.total-score p {
  margin: 0;
  font-size: 1.1em;
}

.question-score {
  display: inline-block;
  margin-left: 10px;
  padding: 2px 8px;
  background-color: #fe2400;
  color: white;
  border-radius: 4px;
  font-size: 0.9em;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Error State */
.error {
  color: red;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .page-container {
    width: 90%;
  }

  .modal-content {
    width: 90%;
  }

  .section-title {
    font-size: 1.25rem;
  }

  .question-container {
    padding-left: 10px;
  }

  .response-image {
    max-width: 100%;
  }

  .inspections-table th,
  .inspections-table td {
    padding: 8px;
    font-size: 14px;
  }

  .inspection-header {
    grid-template-columns: 1fr;
  }

  .scores-summary,
  .section-score,
  .total-score {
    padding: 10px;
  }
}
.score-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score-percentage {
  font-size: 0.9em;
  color: #666;
}

.total-score {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.question-score {
  margin-left: 8px;
  font-weight: bold;
  color: #444;
}

.section-score {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.section-score:last-child {
  border-bottom: none;
}

.scores-summary {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 4px;
  margin: 15px 0;
}

.scores-summary h4 {
  margin-top: 0;
  color: #333;
}