/* Global reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base body styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Footer styles */
.footer {
  background-color: #333;
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer p {
  margin: 0;
}

.btn-logout {
  background-color: #fe2400;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-logout:hover {
  background-color: #cc1e00;
}