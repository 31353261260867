.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(40vh - 50px); 
    padding-top: 150px; 
  }
  
  .login-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  h2 {
    color: #fe2400;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .error-text {
    color: red;
    margin-top: 10px;
    text-align: center;
  }
  .forgot-password-link {
    color: #fe2400; /* Red color */
    font-size: 0.9rem; /* Smaller font size */
    margin-top: 15px; /* Space above the link */
    display: inline-block; /* Allows margin to work properly */
    text-decoration: none; /* No underline initially */
  }
  
  .forgot-password-link:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  .form-group + .forgot-password-link {
    margin-top: 15px; /* Add space between form-group and the link */
  }
  