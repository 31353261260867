/* Admin Page container and layout */
.admin-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px; /* Space below the navbar */
    height: calc(100vh - 50px); /* Takes up the remaining space below the navbar */
  }
  
  .admin-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1200px;
  }
  
  /* Headings */
  h2, h3 {
    color: #fe2400;
    margin-bottom: 20px;
  }
  
  /* Form group styling */
  .form-group {
    margin-bottom: 30px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  /* Table styling */
  .table-container {
    margin-top: 30px;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .users-table th, .users-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .users-table th {
    background-color: #333;
    color: white;
  }
  
  .users-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Error and success messages */
  .error-text {
    color: red;
  }
  
  .success-text {
    color: green;
  }
  
  /* Button styles */
  .btn-primary {
    background-color: #fe2400;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: auto; 
    display: inline-block;
  }
  
  .btn-primary:hover {
    background-color: #cc1e00; /* Darker red on hover */
  }  
  