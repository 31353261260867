/* Mobile navbar styling */
.mobile-navbar {
  background-color: #fe2400;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky; /* Sticky so it stays on top when scrolling */
  top: 0;
  z-index: 1000;
  height: 60px; /* Adjust height based on your design */
}

/* Centered logo in mobile */
.mobile-navbar .navbar-logo {
  display: flex;
  align-items: center;
  margin: 0; /* Ensure vertical centering */
}

.mobile-navbar .navbar-logo img {
  height: 40px; /* Adjust logo size if necessary */
  display: block;
  margin: 0;
}

/* Title on the right of the logo */
.navbar-title {
  font-size: 18px;
  font-weight: bold;
  color: white;
  flex-grow: 1; /* Ensure title stretches out to the right of the logo */
  text-align: left; /* Align title text to the left */
  padding-left: 10px; /* Optional padding to give some spacing */
}

/* Hamburger icon */
.hamburger {
  font-size: 30px;
  cursor: pointer;
  color: white;
}

/* Mobile menu dropdown */
.mobile-menu {
  display: flex;
  flex-direction: column;
  background-color: #fe2400;
  position: fixed; 
  top: 60px; 
  right: 0; 
  width: 155px; 
  z-index: 999;
  border-radius: 0 0 10px 10px;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Links in the mobile menu */
.mobile-menu .nav-link {
  color: white;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid #ccc;
  text-align: right;
}

.mobile-menu .nav-link:hover {
  background-color: #cc1e00;
}

/* Media queries to hide mobile navbar on desktop */
@media (min-width: 769px) {
  .mobile-navbar {
    display: none;
  }
  
  .mobile-menu {
    display: none; /* Hide mobile menu on larger screens */
  }
}