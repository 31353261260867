.page-container {
  display: block;
  width: 55%;
  margin: 20px auto;
  padding: 20px;
}

.existing-templates-container,
.create-template-container {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.main-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* List Styling */
.template-list {
  width: 100%;  /* Full width */
  padding: 0;
  list-style: none;
  margin: 0;
}

.template-item {
  display: flex;
  justify-content: space-between;  /* Space between name and button */
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;  /* Full width */
}

/* Section Styling */
.section-container {
  margin-bottom: 30px;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.section-header h3 {
  margin: 0;
  font-size: 1.5rem;
}

/* Divider between questions */
.question-divider {
  margin: 10px 0;
  height: 2px;
  background-color: #ccc;
}

/* Question Styling */
.question-container {
  margin-bottom: 20px;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.question-header h4 {
  margin: 0;
  font-size: 1.2rem;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.btn-primary,
.btn-submit,
.btn-cancel,
.btn-secondary,
.btn-danger {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #fe2400;
  color: white;
  width: auto; 
  display: inline-block;
}

.btn-primary:hover {
  background-color: #d71f00;
}

.btn-submit {
  background-color: #333;
  color: white;
}

.btn-submit:hover {
  background-color: #555;
}

.btn-cancel {
  background-color: #999;
  color: white;
}

.btn-cancel:hover {
  background-color: #777;
}

.btn-secondary {
  background-color: #007bff;
  color: white;
}

.btn-secondary:hover {
  background-color: #0056b3;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-danger:hover {
  background-color: #c82333;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.options-container {
  margin-top: 15px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.option-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.option-text {
  flex: 1;
  font-size: 16px;
  padding: 8px;
  background-color: #e9ecef;
  border-radius: 4px;
}

.points-input {
  width: 80px !important;
  margin-bottom: 0 !important;
}

/* Override input-field width for specific cases */
.option-item .input-field {
  margin-bottom: 0;
}

.custom-option-input {
  flex: 1;
}

.option-item button.btn-danger {
  padding: 8px 12px;
  margin-left: 10px;
  font-size: 14px;
}