/* Desktop navbar styling */
.navbar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  height: 70px;
  padding: 10px 20px;
  background: linear-gradient(135deg, #fe2400, #d43f1c);
  color: white;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

/* Left navigation links - right-aligned */
.nav-left {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}

/* Right navigation links - left-aligned */
.nav-right {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

/* Navigation links */
.nav-link {
  display: inline-block;
  width: 125px;
  text-align: center;
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  transition: color 0.3s ease;
  line-height: 1.2;
  padding: 8px 0;
}

/* Hover effect for links */
.nav-link:hover {
  color: #333;
}

/* Centered logo */
.navbar-logo {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.navbar-logo img {
  height: 80px;
  margin-top: 1px;
  margin-bottom: -20px;
  position: relative;
}

/* Media queries to hide desktop navbar on mobile */
@media (max-width: 768px) {
  .navbar {
    display: none; /* Hide desktop navbar on mobile */
  }
}