/* Container for the inspection page */
.inspection-container {
  display: block;
  width: 55%;
  margin: 20px auto; /* Centered horizontally */
  padding: 20px;
}

/* Template list container - responsive width */
.template-list {
  width: 100%; /* Full width within container */
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

/* Template item styling */
.template-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8f9fa; /* Slightly different background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

/* Form container - responsive width */
.form-container {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  width: 100%; /* Full width within container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

/* Section container styling */
.form-section {
  margin-top: 20px;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

/* Question container styling */
.question-container {
  margin-bottom: 20px; /* Increased margin */
}

/* Input field styling */
.input-field {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Primary button styling */
.btn-primary {
  background-color: #fe2400;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto; 
  display: inline-block;
}

.btn-primary:hover {
  background-color: #d71f00;
}

/* Submit button styling */
.btn-submit {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #555;
}

/* Error and success messages */
.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  max-width: 400px;
  width: 80%;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-primary {
  margin-top: 10px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .inspection-container,
  .form-container {
    width: 100%; /* Take up 90% of the page width on mobile */
  }
}

/* Add these styles to your existing CSS file */

.scoring-summary {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
}

.total-score {
  background-color: #fe2400;
  color: white;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 15px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.section-score {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 10px;
}

.section-score h4 {
  margin: 0 0 8px 0;
  color: #333;
}

.section-score-allocation {
  color: #666;
  font-size: 0.9em;
  margin-bottom: 15px;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.template-type-badge {
  background-color: #fe2400;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  margin-left: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .scoring-summary {
    padding: 15px;
  }
  
  .section-score {
    padding: 10px;
  }
  
  .total-score {
    font-size: 1.1em;
  }
}